<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title class="title font-weight-bold">
            프린터 대시보드
          </v-card-title>
          <v-card-subtitle>
            메이드올의 모든 프린터를 한곳에서 확인할 수 있습니다.123
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="pa-4 rounded-lg" outlined>
          <v-card-actions class="pb-4">
            <div class="d-flex mb-auto" style="flex-direction: column">
              <div>총 장비 수 : {{ printers.length }} 대</div>
              <div>
                - 메이드올 대구지사 :
                {{
                  printers.filter((e) => e.makerSpace === "메이드올 대구지사")
                    .length
                }}
                대
              </div>
              <div>
                - 경희대학교 국제캠퍼스 :
                {{
                  printers.filter(
                    (e) => e.makerSpace === "경희대학교 국제캠퍼스"
                  ).length
                }}
                대
              </div>
            </div>
            <v-spacer />
            <div class="d-flex" style="flex-direction: column; gap: 12px">
              <div class="d-flex">
                <v-autocomplete
                  v-model="makerSpace"
                  :items="makerSpaceList"
                  cache-items
                  hide-no-data
                  hide-details
                  style="max-width: 240px"
                  clearable
                  outlined
                  dense
                  class="font-weight-regular"
                  label="시설을 선택하세요"
                />
                <v-btn
                  icon
                  @click="refresh"
                  :disabled="fetchLoading"
                  class="ml-4"
                >
                  <v-progress-circular
                    indeterminate
                    v-if="fetchLoading"
                    size="20"
                    width="3"
                    color="primary"
                  />
                  <v-icon v-else> mdi-refresh </v-icon>
                </v-btn>
              </div>
              <div class="d-flex justify-end">
                <v-btn color="primary" @click="addPrinter()">
                  프린터 추가
                </v-btn>
              </div>
            </div>
          </v-card-actions>
          <v-card-text class="pa-2">
            <div
              v-if="printers.length == 0"
              class="py-10 justify-center"
              style="width: 100%"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                "
                class="mx-auto"
              >
                <v-progress-circular
                  indeterminate
                  color="background_dark"
                  size="120"
                  width="8"
                />
                <div class="mt-4">
                  {{ loadingMsg }}
                </div>
              </div>
            </div>
            <v-row v-else class="px-2">
              <v-col
                lg="2"
                md="4"
                sm="6"
                cols="12"
                v-for="printer in printers"
                :key="printer.id"
                class="pa-1"
              >
                <printerCard :printer="printer" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";
import printerCard from "./PrinterCard.vue";

export default {
  name: "Admin-Printer",
  components: { printerCard },
  data() {
    return {
      refreshEnabled: false,
      loadingMsg: "",

      AWSConnection: null,
      makerSpace: null,
      makerSpaceList: ["메이드올 대구지사", "경희대학교 국제캠퍼스"],
      fetchLoading: false,

      subscription: [],
      printers: [],
    };
  },
  beforeCreate() {
    setMeta({
      title: "프린터 대시보드",
      description: "전체 프린터 운용 현황 보기",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  async mounted() {
    this.makerSpaceSearch();
  },
  watch: {
    makerSpace(n, o) {
      if (n !== o) this.makerSpaceSearch();
    },
  },

  methods: {
    async makerSpaceSearch() {
      try {
        this.printers = [];
        this.loadingMsg = "Loading Printer List...";
        this.fetchLoading = true;
        if (this.unsubscribe) {
          this.unsubscribe();
        }
        let query = this.$firebase.firestore().collection("printer");
        if (this.makerSpace) {
          query = query.where("makerSpace", "==", this.makerSpace);
        }
        this.unsubscribe = query.onSnapshot(
          (snapshot) => {
            const printers = snapshot.docs.map((doc) => doc.data());

            const sortedPrinters = printers.sort((a, b) => {
              const extractNumber = (str) => {
                const match = str.match(/\d+/);
                return match ? parseInt(match[0]) : 0;
              };

              const aNumber = extractNumber(a.displayName);
              const bNumber = extractNumber(b.displayName);

              return aNumber - bNumber;
            });
            this.printers = sortedPrinters;
            this.startRefresh();
            this.fetchLoading = false;
          },
          (error) => {
            console.error("프린터 목록 구독 중 오류 발생:", error);
            this.loadingMsg = "프린터 목록을 불러오는 중 오류가 발생했습니다.";
            this.fetchLoading = false;
          }
        );
      } catch (error) {
        console.error("프린터 목록을 가져오는 중 오류 발생:", error);
        this.loadingMsg = "프린터 목록을 불러오는 중 오류가 발생했습니다.";
        this.fetchLoading = false;
      }
    },
    startRefresh() {
      this.refresh();
      // if (!this.refreshEnabled) {
      //   this.refreshEnabled = true;
      //   const refreshInterval = setInterval(() => {
      //     if (this.refreshEnabled) {
      //       this.refresh();
      //     } else {
      //       clearInterval(refreshInterval);
      //     }
      //   }, 30000);
      // }
    },
    refresh() {
      this.fetchLoading = true;
      const promises = this.printers.map((printer) =>
        this.updatePrinterStatus(printer)
      );
      Promise.all(promises).finally(() => {
        this.fetchLoading = false;
      });
    },
    async updatePrinterStatus(printer) {
      try {
        const statusData = await this.$axios.get(`/printer/getStatus/${printer.id}`);

        const index = this.printers.findIndex((p) => p.id === printer.id);
        if (index !== -1) {
          const updatedPrinter = {
            ...this.printers[index],
            getStat: statusData.data,
          };
          this.$set(this.printers, index, updatedPrinter);
        }
      } catch (error) {
        console.error(`프린터 ${printer.id} 상태 업데이트 중 오류:`, error);
      }
    },

    addPrinter() {
      this.$axios.put("/printer").then(() => {
        this.makerSpaceSearch();
      });
    },
  },
  beforeDestroy() {
    this.refreshEnabled = false;
  },
};
</script>

<style scoped>
::v-deep .v-progress-linear,
::v-deep .v-progress-linear__background {
  background: var(--v-background_dark-base) !important;
  opacity: 1 !important;
}
::v-deep .v-progress-linear__determinate {
  background: linear-gradient(to right, #36d1dc, #5b86e5) !important;
  border-radius: 6px !important;
}
</style>
