<template>
  <v-card
    class="pa-3 d-flex"
    style="flex-direction: column; gap: 12px; border-radius: 8px"
    flat
    outlined
  >
    <v-card-actions class="pa-0 subtitle-2" style="height: 20px">
      <v-btn @click="printer.select = !printer.select" icon x-small>
        <v-icon class="mx-auto" size="16" color="primary">
          {{ printer.select ? "mdi-check-circle" : "mdi-circle-outline" }}
        </v-icon>
      </v-btn>
      <span class="ml-2" :class="getStatus().class">
        {{ getStatus().text }}
      </span>
      <v-spacer />
      <v-btn icon x-small @click="openPrinter()">
        <v-icon size="16"> mdi-open-in-new </v-icon>
      </v-btn>
    </v-card-actions>
    
    <!-- 프린터 이미지/스트림 -->
    <div
      class="image-container"
      @mouseenter="startStream"
      @mouseleave="stopStream"
    >
      <v-img
        v-if="!showStream"
        :src="printer.getStat && printer.getStat.snapshot"
        :aspect-ratio="16/9"
        style="border-radius: 4px; position: relative"
      >
        <template v-slot:placeholder>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
            "
          >
            <v-progress-circular indeterminate color="background_dark" />
          </div>
        </template>
      </v-img>
      <img
        v-if="showStream"
        :src="streamUrl"
        style="
          border-radius: 4px;
          width: 100%;
          height: auto;
          aspect-ratio: 16/9;
        "
      />
    </div>

    <v-card-title
      class="pa-0 subtitle-2 d-flex"
      style="flex-direction: column; gap: 12px; width: 100%"
    >
      <div style="width: 100%">
        {{ printer.makerSpace }} - {{ printer.displayName }}
      </div>
      <div class="d-flex" style="width: 100%">
        <div>
          <v-icon size="14" color="black" class="mr-1">
            mdi-printer-3d-nozzle-heat
          </v-icon>
          {{
            printer.getStat ? Math.floor(printer.getStat.nozzle_temp) : "000"
          }}
          /
          {{ printer.getStat ? printer.getStat.nozzle_target : "000" }}
        </div>
        <v-spacer />
        <div>
          <v-icon size="14" color="black" class="mr-1"> mdi-radiator </v-icon>
          {{ printer.getStat ? Math.floor(printer.getStat.bed_temp) : "00" }}
          /
          {{ printer.getStat ? printer.getStat.bed_target : "00" }}
        </div>
      </div>
      <div style="width: 100%" class="d-flex">
        <v-icon size="14" color="black" class="mr-1"> mdi-file </v-icon>
        <div
          class="overflow-x-hidden"
          style="height: 15px; white-space: nowrap"
        >
          {{
            printer.getStat
              ? printer.getStat.filename.replace(/\.gcode$/, "")
              : "-"
          }}
        </div>
      </div>

      <v-progress-linear
        :value="
          getStatus().text == 'Complete'
            ? '100'
            : printer.getStat &&
              Math.floor(
                (printer.getStat.print_duration * 100) /
                  printer.getStat.estimated_time
              )
        "
        height="12"
        style="border-radius: 4px"
      />
      <div class="d-flex" style="width: 100%">
        <div v-if="getStatus().text == 'Complete'">
          걸린 시간 :
          {{ formatHours(printer.getStat?.print_duration) }}시간
          {{ formatMinutes(printer.getStat?.print_duration) }}분
        </div>
        <div v-else>
          남은 시간 :
          {{ formatRemainingHours() }}시간
          {{ formatRemainingMinutes() }}분
        </div>
        <v-spacer />
        <div>
          {{ getProgressValue() }}%
        </div>
      </div>
    </v-card-title>
    
    <!-- 액션 버튼 -->
    <v-card-actions class="pa-0">
      <div class="d-flex justify-space-between" style="width: 100%">
        <v-btn
          v-for="(button, index) in currentButtonSet"
          :key="index"
          :loading="btnLoading"
          depressed
          class="mx-0 px-0"
          height="32px"
          :min-width="currentButtonSet.length == 1 ? '100%' : '30%'"
          style="border: 1px solid var(--v-background_dark-base)"
          @click="handleButtonClick(button.action)"
        >
          <v-icon :size="20">{{ button.icon }}</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PrinterCard",
  props: { 
    printer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      btnLoading: false,
      buttonSets: {
        Printing: [
          {
            icon: "mdi-play-pause",
            action: this.getStatus().text == "paused" ? "resume" : "pause",
          },
          { icon: "mdi-stop", action: "abort" },
          { icon: "mdi-car-brake-alert", action: "emergency" },
        ],
        Ready: [
          { icon: "mdi-fast-forward", action: "print" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Complete: [
          { icon: "mdi-fast-forward", action: "print" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Paused: [
          { icon: "mdi-play-pause", action: "resume" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Cancelled: [
          { icon: "mdi-fast-forward", action: "print" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Shutdown: [
          { icon: "mdi-refresh", action: "Firmware_Restart" },
        ],
        all: [
          { icon: "mdi-fast-forward", action: "print" },
          { icon: "mdi-play-pause", action: "pause_resume" },
          { icon: "mdi-stop", action: "abort" },
          { icon: "mdi-car-brake-alert", action: "emergency" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
          { icon: "", action: "G28" },
          { icon: "", action: "HOME_Z" },
          { icon: "", action: "Firmware_Restart" },
        ],
      },
      showStream: false,
      streamUrl: null,
    };
  },
  computed: {
    currentButtonSet() {
      return this.buttonSets[this.getStatus().text] || [];
    },
    printerBaseUrl() {
      return `https://printer-${this.printer.id}.madeall3d.com`;
    }
  },
  methods: {
    openPrinter() {
      window.open(this.printerBaseUrl, "_blank");
    },
    getStatus() {
      const res = { class: "", text: "" };
      switch (this.printer.getStat && this.printer.getStat.status) {
        case "standby":
          res.class = "onSurface_dark--text";
          res.text = "Ready";
          break;
        case "printing":
          res.class = "primary--text";
          res.text = "Printing";
          break;
        case "complete":
          res.class = "success--text";
          res.text = "Complete";
          break;
        case "cancelled":
          res.class = "warning--text";
          res.text = "Cancelled";
          break;
        case "shutdown":
          res.class = "error--text";
          res.text = "Shutdown";
          break;
        case "paused":
          res.class = "warning--text";
          res.text = "Paused";
          break;
        default:
          res.class = "background_dark--text";
          res.text = "Not Connected";
          break;
      }
      return res;
    },
    getProgressValue() {
      if (this.getStatus().text === "Complete") {
        return 100;
      }
      
      if (!this.printer.getStat || !this.printer.getStat.print_duration || !this.printer.getStat.estimated_time) {
        return 0;
      }
      
      return Math.floor((this.printer.getStat.print_duration * 100) / this.printer.getStat.estimated_time);
    },
    formatHours(duration) {
      if (!duration) return "00";
      return Math.floor(duration / 3600);
    },
    formatMinutes(duration) {
      if (!duration) return "00";
      return Math.floor((duration % 3600) / 60);
    },
    formatRemainingHours() {
      if (!this.printer.getStat || !this.printer.getStat.estimated_time || !this.printer.getStat.print_duration) {
        return "00";
      }
      const remaining = this.printer.getStat.estimated_time - this.printer.getStat.print_duration;
      return Math.floor(remaining / 3600);
    },
    formatRemainingMinutes() {
      if (!this.printer.getStat || !this.printer.getStat.estimated_time || !this.printer.getStat.print_duration) {
        return "00";
      }
      const remaining = this.printer.getStat.estimated_time - this.printer.getStat.print_duration;
      return Math.floor((remaining % 3600) / 60);
    },
    async handleButtonClick(action) {
      this.btnLoading = true;
      try {
        await this.$axios.post(`/printer/action/${this.printer.id}`, { action });
      } catch (error) {
        console.error(`액션 실행 중 오류 발생: ${action}`, error);
      } finally {
        this.btnLoading = false;
      }
    },
    startStream() {
      this.showStream = true;
      this.streamUrl = `${this.printerBaseUrl}/webcam/?action=stream&t=${Date.now()}`;
    },
    stopStream() {
      this.showStream = false;
      this.streamUrl = null;
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 4px;
  overflow: hidden;
}
</style>
